import React from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import {
  useMutation,
} from "@apollo/client";
import { localStorage, window } from "window-or-global";

import {logoutMutation} from "../logic/user";
import logoCircle from "../../images/iglu/092022/master.png";
import Main from "../main";

export default function Index(props) {
  return (
    <Main>
      <Header {...props} />
    </Main>
  )
}

function Header() {
  const [logout] = useMutation(logoutMutation);

  async function onHandleLogout() {
    await logout();
    localStorage.removeItem("authToken");
    return window.location = "/";
  }

  return (
    <header className="bg-grey">
      <Container>
        <Navbar bg="grey" variant="dark" expand="lg">
          <Navbar.Brand href="/">
            <img src={logoCircle} alt="logo" className="header-logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto">
              <Nav.Link onClick={async() => onHandleLogout()}>Logout</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </Container>
    </header>
  );
}
